import Header from "../header/Header";
import classes from "./ReportResult.module.css";
// copied
import axios from "axios";
import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router";
import { useNavigate } from "react-router-dom";
import enter from "../../assets/Enter-arrow.png";
import { Context } from "../store/StateProvider";
import "./Result.css";
import TraitsCard from "../../assets/TraitsCard.png";
import P1 from "../../assets/PersonalityTraitsNums/1.png";
import P2 from "../../assets/PersonalityTraitsNums/2.png";
import P3 from "../../assets/PersonalityTraitsNums/3.png";
import P4 from "../../assets/PersonalityTraitsNums/4.png";
import P5 from "../../assets/PersonalityTraitsNums/5.png";
import P6 from "../../assets/PersonalityTraitsNums/6.png";
import P7 from "../../assets/PersonalityTraitsNums/7.png";
import P8 from "../../assets/PersonalityTraitsNums/8.png";
import P9 from "../../assets/PersonalityTraitsNums/9.png";
import P10 from "../../assets/PersonalityTraitsNums/10.png";

import { WalletContext } from "../store/WalletProvider";
import { SessionContext } from "../store/SessionProvider";

let previousTestName = "";
const ReportResult = () => {
  // copied
  const [state, dispatch] = Context();
  const [currentTestId, setCurrentTestId] = useState();
  const [currentTestTemplateId, setCurrentTestTemplateId] = useState();
  const [nextTestTemplateId, setNextTestTemplateId] = useState();

  const [bigFiveResultText, setBigFiveResultText] = useState("");
  const [darkTriadResultText, setDarkTriadResultText] = useState("");
  const [bigFiveHighestTrait, setBigFiveHighestTrait] = useState();
  const [darkTraidHighestTrait, setDarkTraidHighestTrait] = useState();
  const [buttonrender, setButtonrender] = useState(true);
  const [showTemplate1Test, setShowTemplate1Test] = useState(false);
  const [winner, setWinner] = useState(undefined);
  const [mostCommonScoreDistribution, setMostCommonScoreDistribution] = useState([]);
  // const [traitsPercentages, setTraistPercentages] = useState([]);

  const [bigFiveTraits, setBigFiveTraits] = useState([]);

  const [extraversion, setExtraversion] = useState({});
  const [openness, setOpenness] = useState({});
  const [neuroticism, setNeuroticism] = useState({});
  const [agreeableness, setAgreeableness] = useState({});
  const [conscientiousness, setConscientiousness] = useState({});
  const [scriptData, setScriptData] = useState("");
  const [ { address }] = WalletContext();
  const [ { email, visitorId } ] = SessionContext();

  const [loading, setLoading] = useState(false);
  let { id } = useParams();
  let navigate = useNavigate();
  const percentages = useLocation();
  // console.log(percentages.state, "report url");

  let winnerVariable = undefined, mostCommonScoreDistributionVariable = [];
  // url id logic
  useEffect(() => {
    axios.get(`${global.apiAdress}/api/code-snippets/list`).then(response => {
      console.log(response);
      if (response.data.status = 200) {
        let CodeSnippetsRaw = response.data.code_snippets;
        const CodeSnippets = CodeSnippetsRaw.filter(
          (e) => e.code_page == "Result Page"
        );
        if (CodeSnippets.length > 0) {
          setScriptData(CodeSnippets[0].code);
        }
        console.log(CodeSnippets)
      }
    })
    setTimeout(function () {
      const card = document.querySelector(".card__inner_temp_result");
      card.classList.toggle('is-flipped');
    }, 3000);
    console.log(percentages);
    console.log(state.winner);
    if (percentages.state != null && percentages.state != undefined) {
      percentages.state.forEach(item => {
        if (item.traitId == "10") {
          setExtraversion({
            trait_id: item.trait_id,
            marks: item.marks,
            percentage: item.percentage,
            questions: item.questions,
            test_id: item.test_id
          });
        }
        else if (item.traitId == "11") {
          setOpenness({
            trait_id: item.trait_id,
            marks: item.marks,
            percentage: item.percentage,
            questions: item.questions,
            test_id: item.test_id
          });
        }
        else if (item.traitId == "12") {
          setConscientiousness({
            trait_id: item.trait_id,
            marks: item.marks,
            percentage: item.percentage,
            questions: item.questions,
            test_id: item.test_id
          });
        }
        else if (item.traitId == "13") {
          setAgreeableness({
            trait_id: item.trait_id,
            marks: item.marks,
            percentage: item.percentage,
            questions: item.questions,
            test_id: item.test_id
          });
        }
        else if (item.traitId == "14") {
          setNeuroticism({
            trait_id: item.trait_id,
            marks: item.marks,
            percentage: item.percentage,
            questions: item.questions,
            test_id: item.test_id
          });
        }
      });
    }

    setBigFiveTraits(percentages.state);
    setWinner(state.winner);
    winnerVariable = state.winner;
    mostCommonScoreDistributionVariable = state.mostCommonScoreDistribution;
    setMostCommonScoreDistribution(state.mostCommonScoreDistribution)
    axios.get(`${global.apiAdress}/api/user/all-tests`).then((response) => {
      if (response.status === 200 && response.data.tests.length > 0) {
        let currentTestArray = [];
        response.data.tests.forEach((test, index) => {
          if (test.id == id) {
            if (parseInt(test.template_id) === 1) {
              if (
                test.test_title.includes("big five") ||
                test.test_title.includes("big 5") ||
                test.test_title.includes("Big Five") ||
                test.test_title.includes("Big five") ||
                test.test_title.includes("Big 5") ||
                test.test_title.includes("big5") ||
                test.test_title.includes("bigfive") ||
                test.test_title.includes("BigFive") ||
                test.test_title.includes("Bigfive") ||
                test.test_title.includes("bigFive")
              ) {
                previousTestName = "bigFive";
                setShowTemplate1Test(true);
                const currentTestTraitPercentages = percentages.state.filter(
                  (percentage) => {
                    return parseInt(percentage.test_id) === parseInt(id);
                  }
                );
                setBigFiveResultText(
                  "<p>This is your Personality Card! This card shows your Big 5 Traits results. We will show you what this means in the results. </p> "
                );
                const percent = currentTestTraitPercentages.map(
                  (percent) => percent.percentage
                );

                const highestPercent = Math.max(...percent);

                const highestPercentTrait = currentTestTraitPercentages.filter(
                  (percentage) => {
                    return (
                      parseInt(percentage.percentage) ===
                      parseInt(highestPercent)
                    );
                  }
                );
                if (highestPercentTrait.length > 0) {
                  axios
                    .get(
                      `${global.apiAdress}/api/user/get-highest-trait/${highestPercentTrait[0].traitId}`
                    )
                    .then((response) => {
                      if (response.status === 200) {
                        setBigFiveHighestTrait(response.data.traitName);
                      }
                    });
                }
              } else {
                previousTestName = "darkTraid";
                setShowTemplate1Test(false);
                const currentTestTraitPercentages = percentages.state.filter(
                  (percentage) => {
                    return parseInt(percentage.test_id) === parseInt(id);
                  }
                );
                setDarkTriadResultText(
                  "This card includes your DarkTraid, which is the only scientifically backed model. We will show you what this card means in the result"
                );
                const percent = currentTestTraitPercentages.map(
                  (percent) => percent.percentage
                );
                const highestPercent = Math.max(...percent);
                const highestPercentTrait = currentTestTraitPercentages.filter(
                  (percentage) => {
                    return (
                      parseInt(percentage.percentage) ===
                      parseInt(highestPercent)
                    );
                  }
                );
                if (highestPercentTrait.length > 0) {
                  axios
                    .get(
                      `${global.apiAdress}/api/user/get-highest-trait/${highestPercentTrait[0].traitId}`
                    )
                    .then((response) => {
                      if (response.status === 200) {
                        setDarkTraidHighestTrait(response.data.traitName);
                      }
                    });
                }
              }
            }

            setCurrentTestTemplateId(response.data.tests[index].template_id);
            if (index < response.data.tests.length - 1) {
              if (parseInt(response.data.tests[index + 1].template_id) === 1) {
                if (response.data.tests[index + 1].number_of_traits > 0) {
                  currentTestArray = [
                    {
                      id: response.data.tests[index + 1].id,
                      name: response.data.tests[index + 1].test_title,
                      template_id: response.data.tests[index + 1].template_id,
                    },
                  ];
                }
              } else {
                currentTestArray = [
                  {
                    id: response.data.tests[index + 1].id,
                    name: response.data.tests[index + 1].test_title,
                    template_id: response.data.tests[index + 1].template_id,
                  },
                ];
              }
            }
          }
        });
        if (currentTestArray.length > 0) {
          setCurrentTestId(currentTestArray[0].id);
          const template_id = currentTestArray[0].template_id;
          setNextTestTemplateId(template_id);



          if (template_id === 1) {
            setButtonrender(true);
          } else {
            setButtonrender(true);
          }
        } else {
          setButtonrender(false);
        }
        if (previousTestName == "darkTraid" && previousTestName != "") {
          setLoading(true);
          if (currentTestArray.length > 0) {
            previousTestName = "";
            if (nextTestTemplateId == 1) {
              navigate(`/test/${currentTestArray[0].id}`, { state: percentages.state });
            } else {
              navigate(`/test2/${currentTestArray[0].id}`, { state: percentages.state });
            }
          }
          else {
            axios.get(`${global.apiAdress}/api/test4/demographic-answer/turnonoff/get`).then(response => {
              console.log(response)
              console.log(response.data.basic_settings[0].demographics_on_off)
              console.log(JSON.parse(response.data.basic_settings[0].demographics_on_off))
              if (JSON.parse(response.data.basic_settings[0].demographics_on_off).value == "turnon") {
                setLoading(false)
                navigate("/test4", { state: percentages.state });
              }
              else {
                setLoading(false)
                console.log("winner Result Short: ", winnerVariable);
                console.log("mostCommonScoreDistribution Result Short: ", mostCommonScoreDistributionVariable);
                localStorage.setItem("winner", JSON.stringify(winnerVariable));
                localStorage.setItem("mostCommonScoreDistribution", JSON.stringify(mostCommonScoreDistributionVariable));
                // console.log("percentages", percentages)
                // console.log("subtraitsPercentages", state.subtraitsPercentages)

                let mostCommonScoreDistribution = [];
                mostCommonScoreDistributionVariable.forEach(element => {
                  // if (!((element.value_id).includes("."))) {
                    mostCommonScoreDistribution.push({
                      card_image: element.card_image,
                      id: element.id,
                      most_Common_Distribution: element.most_Common_Distribution,
                      total_Score_Distribution: element.total_Score_Distribution,
                      value_name: element.value_name
                    })
                  // }
                });

                let winnerDetails = {
                  card_image: winnerVariable.card_image,
                  id: winnerVariable.id,
                  value_id: winnerVariable.value_id,
                  value_name: winnerVariable.value_name
                };


                let rarity = "Epic";
                let yieldData = "";
                let payoutLevel = "7";
                let lifetimeEarning = "7201";
                let EarningsDetailLink = "app.personalitynft.com/earnings-1vbowna1234r";

                let nftId = makeNftId(6);
                console.log(nftId);
                let discountCode = "1a2b67";
                let discountLink = "app.personalitynft.com/discount-code-1vbowna1234r"
                let discountCodeUsed = "none";
                let walletAddress = address;

                debugger
                const resultData = {
                  winnerVariable: JSON.stringify(winnerDetails),
                  mostCommonScoreDistributionVariable: JSON.stringify(mostCommonScoreDistribution),
                  percentages: JSON.stringify(percentages.state),
                  subtraitsPercentages: JSON.stringify(state.subtraitsPercentages),
                  visitorId,

                  rarity: rarity,
                  yieldData: yieldData,
                  payoutLevel: payoutLevel,
                  lifetimeEarning: lifetimeEarning,

                  earningsDetailLink: EarningsDetailLink,
                  nftId: nftId,
                  discountCode: discountCode,
                  discountLink: discountLink,
                  discountCodeUsed: discountCodeUsed,
                  walletAddress: walletAddress,
                  userEmail: email
                }
                axios.post(`${global.apiAdress}/api/result-page/store-user-result`, resultData).then(response => {
                  if (response.status === 200) {
                    console.log(response);
                    console.log(response.data.id);
                  }
                }).catch(() => {
                }).finally(() => {
                  window.location.href = "/result";
                })

              }
            })


            dispatch({ type: "testStarted", isTesting: false });
          }
        }
      }
    });
  }, []);
  function makeNftId(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  const test2Handler = () => {
    if (nextTestTemplateId == 1) {
      navigate(`/test/${currentTestId}`, { state: percentages.state });
    } else {
      navigate(`/test2/${currentTestId}`, { state: percentages.state });
    }
  };
  const resultHandler = () => {
    navigate("/result", { state: percentages.state });
    dispatch({ type: "testStarted", isTesting: false });
  };
  function downloadSVG() {

    console.log(document.getElementById("personalityCard"))
    var svgData = document.getElementById("personalityCard").outerHTML;
    console.log(svgData)
    var svgBlob = new Blob([svgData], { type: "svg+xml;charset=utf-8" });
    console.log(svgBlob)
    var svgUrl = URL.createObjectURL(svgBlob);
    console.log(svgUrl)
    var downloadLink = document.createElement("a");
    downloadLink.href = svgUrl;
    console.log(downloadLink)
    downloadLink.download = "personality_card.svg";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink)
  }
  // copied end
  if (loading == true) {
    return (
      <div className="container-fluid  h-100">
        <div className="row h-100">
          <div className="col-11  px-0 mx-auto h-100 ">
            <div className={` ${classes.mainBoxWrapper}`}>
              <Header />
              <div className={`container ${classes.mainBox}`}>
                {/* copied */}
                <h1 className="text-center text-white my-5">Loading...</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
  else if (parseInt(currentTestTemplateId) === 1) {
    return (
      <div className="container-fluid  h-100">
        <div dangerouslySetInnerHTML={{ __html: scriptData }} />
        <div className="row h-100">
          <div className="col-11 px-0 mx-auto h-100 ">
            <div className={` ${classes.mainBoxWrapper}`}>
              <Header />
              <div className={`container ${classes.mainBox}`}>
                {/* copied */}
                <div className="row mx-0 py-5 virtualCardCenter">
                  <div className="virtualCardCenter text-center">
                    <div class="card__inner_temp_result text-center align-items-center" onClick={(e) => {
                      const card = document.querySelector(".card__inner_temp_result");
                      card.classList.toggle('is-flipped');
                    }}>

                      <div class="card__face_temp_result card__face_temp_result--back">
                        <svg onClick={(e) => {
                          // svgToCanvas()
                        }} id="personalityCard"
                          name="personalityCard" width="321" height="469"
                          xmlns="http://www.w3.org/2000/svg">
                          <image href={TraitsCard} width="321" height="469" />
                          <image href={
                            extraversion != {} ?
                              parseInt(Math.round((extraversion.percentage / 10.00))) == "1" ? P1
                                : parseInt(Math.round((extraversion.percentage / 10.00))) == "2" ? P2
                                  : parseInt(Math.round((extraversion.percentage / 10.00))) == "3" ? P3
                                    : parseInt(Math.round((extraversion.percentage / 10.00))) == "4" ? P4
                                      : parseInt(Math.round((extraversion.percentage / 10.00))) == "5" ? P5
                                        : parseInt(Math.round((extraversion.percentage / 10.00))) == "6" ? P6
                                          : parseInt(Math.round((extraversion.percentage / 10.00))) == "7" ? P7
                                            : parseInt(Math.round((extraversion.percentage / 10.00))) == "8" ? P8
                                              : parseInt(Math.round((extraversion.percentage / 10.00))) == "9" ? P9
                                                : parseInt(Math.round((extraversion.percentage / 10.00))) == "10" ? P10
                                                  : ''
                              : ''}
                            x="93" y="151" width="48" height="48" />
                          <image href={openness != {} ?
                            parseInt(Math.round((openness.percentage / 10.00))) == "1" ? P1
                              : parseInt(Math.round((openness.percentage / 10.00))) == "2" ? P2
                                : parseInt(Math.round((openness.percentage / 10.00))) == "3" ? P3
                                  : parseInt(Math.round((openness.percentage / 10.00))) == "4" ? P4
                                    : parseInt(Math.round((openness.percentage / 10.00))) == "5" ? P5
                                      : parseInt(Math.round((openness.percentage / 10.00))) == "6" ? P6
                                        : parseInt(Math.round((openness.percentage / 10.00))) == "7" ? P7
                                          : parseInt(Math.round((openness.percentage / 10.00))) == "8" ? P8
                                            : parseInt(Math.round((openness.percentage / 10.00))) == "9" ? P9
                                              : parseInt(Math.round((openness.percentage / 10.00))) == "10" ? P10
                                                : ''
                            : ''} x="175" y="151" width="48" height="48" />
                          <image href={neuroticism != {} ?
                            parseInt(Math.round((neuroticism.percentage / 10.00))) == "1" ? P1
                              : parseInt(Math.round((neuroticism.percentage / 10.00))) == "2" ? P2
                                : parseInt(Math.round((neuroticism.percentage / 10.00))) == "3" ? P3
                                  : parseInt(Math.round((neuroticism.percentage / 10.00))) == "4" ? P4
                                    : parseInt(Math.round((neuroticism.percentage / 10.00))) == "5" ? P5
                                      : parseInt(Math.round((neuroticism.percentage / 10.00))) == "6" ? P6
                                        : parseInt(Math.round((neuroticism.percentage / 10.00))) == "7" ? P7
                                          : parseInt(Math.round((neuroticism.percentage / 10.00))) == "8" ? P8
                                            : parseInt(Math.round((neuroticism.percentage / 10.00))) == "9" ? P9
                                              : parseInt(Math.round((neuroticism.percentage / 10.00))) == "10" ? P10
                                                : ''
                            : ''} x="93" y="243" width="48" height="48" />
                          <image href={agreeableness != {} ?
                            parseInt(Math.round((agreeableness.percentage / 10.00))) == "1" ? P1
                              : parseInt(Math.round((agreeableness.percentage / 10.00))) == "2" ? P2
                                : parseInt(Math.round((agreeableness.percentage / 10.00))) == "3" ? P3
                                  : parseInt(Math.round((agreeableness.percentage / 10.00))) == "4" ? P4
                                    : parseInt(Math.round((agreeableness.percentage / 10.00))) == "5" ? P5
                                      : parseInt(Math.round((agreeableness.percentage / 10.00))) == "6" ? P6
                                        : parseInt(Math.round((agreeableness.percentage / 10.00))) == "7" ? P7
                                          : parseInt(Math.round((agreeableness.percentage / 10.00))) == "8" ? P8
                                            : parseInt(Math.round((agreeableness.percentage / 10.00))) == "9" ? P9
                                              : parseInt(Math.round((agreeableness.percentage / 10.00))) == "10" ? P10
                                                : ''
                            : ''} x="134" y="257" width="48" height="48" />
                          <image href={conscientiousness != {} ?
                            parseInt(Math.round((conscientiousness.percentage / 10.00))) == "1" ? P1
                              : parseInt(Math.round((conscientiousness.percentage / 10.00))) == "2" ? P2
                                : parseInt(Math.round((conscientiousness.percentage / 10.00))) == "3" ? P3
                                  : parseInt(Math.round((conscientiousness.percentage / 10.00))) == "4" ? P4
                                    : parseInt(Math.round((conscientiousness.percentage / 10.00))) == "5" ? P5
                                      : parseInt(Math.round((conscientiousness.percentage / 10.00))) == "6" ? P6
                                        : parseInt(Math.round((conscientiousness.percentage / 10.00))) == "7" ? P7
                                          : parseInt(Math.round((conscientiousness.percentage / 10.00))) == "8" ? P8
                                            : parseInt(Math.round((conscientiousness.percentage / 10.00))) == "9" ? P9
                                              : parseInt(Math.round((conscientiousness.percentage / 10.00))) == "10" ? P10
                                                : ''
                            : ''} x="177" y="243" width="48" height="48" />
                        </svg>
                      </div>
                      <div class="card__face_temp_result card__face_temp_result--front">
                        <img
                          id="worldViewCardImage"
                          style={{ width: 321, height: 469, minHeight: 469, cursor: "pointer" }}
                          className="resultCardMain1"
                          src={winner != undefined ? "https://pdfapi.personalitynft.com/public/images/" + winner.card_image : null}
                          alt="card1"
                        />
                      </div>
                    </div>
                    {/* <h4 className="resultCardETextShort">
                      {parseInt(bigFiveTraits[0].percentage / 10)}
                    </h4>
                    <h4 className="resultCardOTextShort">
                      {parseInt(bigFiveTraits[1].percentage / 10)}
                    </h4>
                    <h4 className="resultCardNTextShort">
                      {parseInt(bigFiveTraits[4].percentage / 10)}
                    </h4>
                    <h4 className="resultCardATextShort">
                      {parseInt(bigFiveTraits[3].percentage / 10)}
                    </h4>
                    <h4 className="resultCardCTextShort">
                      {parseInt(bigFiveTraits[2].percentage / 10)}
                    </h4> */}
                  </div>
                  <div className="col-md-7 px-0 text-center ">
                    <h2 className={`mt-5 ${classes["reportHead"]}`}>
                      {showTemplate1Test ? (
                        <span>Your Highest Trait is {bigFiveHighestTrait}</span>
                      ) : (
                        <span>Dark Triad {darkTraidHighestTrait}</span>
                      )}
                    </h2>
                    {showTemplate1Test ? (
                      <p dangerouslySetInnerHTML={{ __html: bigFiveResultText }} className={`${classes["reportPara"]}`}>

                      </p>
                    ) : (
                      <p className={`${classes["reportPara"]}`}>
                        {darkTriadResultText}
                      </p>
                    )}
                    {buttonrender ? (
                      <button
                        onClick={() => test2Handler(id)}
                        className={`btn  ${classes["reportBtn"]}`}
                      >
                        Continue
                      </button>
                    ) : (
                      <button
                        onClick={resultHandler}
                        className={`btn  ${classes["reportBtn"]}`}
                      >
                        Result
                      </button>
                    )}
                    <span className={`${classes["reportEnter"]}`}>
                      press
                      <span className={`${classes["reportEnter"]}`}>
                        Enter <img src={enter} alt="enter" />
                      </span>
                    </span>
                  </div>
                </div>
                {/* copied end */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  } else if (parseInt(currentTestTemplateId) === 2) {
    return (
      <div className="container-fluid  h-100">
        <div className="row h-100">
          <div className="col-11  px-0 mx-auto h-100 ">
            <div className={` ${classes.mainBoxWrapper}`}>
              <Header />
              <div className={`container ${classes.mainBox}`}>
                {/* copied */}

                <div className="row mx-0 py-5">
                  <div className="col-md-5  text-center ">
                    <img
                      id="worldViewCardImage"
                      // data-aos="zoom-in"
                      style={{ width: 321, height: 469, minHeight: 469, cursor: "pointer" }}
                      // className={`${classes["cardImage"]}`}
                      // className={`resultCardMain1 ${classes["rotateNow"]}`}
                      className="resultCardMain1 flip"
                      src={winner != undefined ? "https://pdfapi.personalitynft.com/public/images/" + winner.card_image : null}
                      alt="card1"
                      onClick={(e) => {
                        const worldViewCardImage = document.querySelector("#worldViewCardImage");
                        worldViewCardImage.classList.remove('flip');
                        void worldViewCardImage.offsetWidth
                        worldViewCardImage.classList.add('flip');
                      }}
                    />
                    {/* <img style={{width: 240,height: 350}} className="worldViewImg" src={winner!=undefined ? "https://pdfapi.personalitynft.com/public/images/"+winner.card_image : null} alt="card1" /> */}
                  </div>
                  <div className="col-md-7 px-0  text-center ">
                    <h2 className={`mt-2 mt-md-5 ${classes["reportHead"]}`}>
                      World View: {winner != undefined ? winner.value_name : null}
                    </h2>
                    <p className={`${classes["reportPara"]}`}>
                      Congradulations! You earned your World View Card. We will
                      show you what this card means in the result.
                    </p>
                    {buttonrender ? (
                      <button
                        onClick={() => test2Handler(id)}
                        className={`btn  ${classes["reportBtn"]}`}
                      >
                        Continue
                      </button>
                    ) : (
                      <button
                        onClick={resultHandler}
                        className={`btn  ${classes["reportBtn"]}`}
                      >
                        Result
                      </button>
                    )}
                    <span className={`${classes["reportEnter"]}`}>
                      press
                      <span className={`${classes["reportEnter"]}`}>
                        Enter <img src={enter} alt="enter" />
                      </span>
                    </span>
                  </div>
                </div>
                {/* copied end */}
              </div>
            </div>
          </div>
        </div>
      </div>
    );

  } else {
    return (
      <div className="container-fluid  h-100">
        <div className="row h-100">
          <div className="col-11  px-0 mx-auto h-100 ">
            <div className={` ${classes.mainBoxWrapper}`}>
              <Header />
              <div className={`container ${classes.mainBox}`}>
                {/* copied */}
                <h1 className="text-center text-white my-5">Loading...</h1>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
};

export default ReportResult;
