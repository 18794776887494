import React, { useCallback, useState } from "react";
import cardLoadingAnimation from "../../assets/trait_card_loading.gif";

const TraitCard = ({ 
  conscientiousness, 
  agreeableness, 
  openness, 
  extraversion, 
  neuroticism, 
  narcissism,
  psychopathy,
  machiavellianism,
  winnerCardName,
  height = '580px',
  hideMessage,
}) => {
  const [loading, setLoading] = useState(true);

  const onLoad = useCallback(async () => {
    setTimeout(() => setLoading(false), 10000);
  }, []);

  let url = 'https://personality-nft.web.app?';
  if (conscientiousness) {
    url += `c=${parseInt(Math.round((conscientiousness.percentage / 10.00)))}&`
  }
  if (agreeableness) {
    url += `a=${parseInt(Math.round((agreeableness.percentage / 10.00)))}&`
  }
  if (openness) {
    url += `o=${parseInt(Math.round((openness.percentage / 10.00)))}&`
  }
  if (extraversion) {
    url += `e=${parseInt(Math.round((extraversion.percentage / 10.00)))}&`
  }
  if (neuroticism) {
    url += `n=${parseInt(Math.round((neuroticism.percentage / 10.00)))}&`
  }
  if (narcissism) {
    url += `dtn=${parseInt(Math.round((narcissism.percentage / 10.00)))}&`
  }
  if (machiavellianism) {
    url += `dtm=${parseInt(Math.round((machiavellianism.percentage / 10.00)))}&`
  }
  if (psychopathy) {
    url += `dtp=${parseInt(Math.round((psychopathy.percentage / 10.00)))}&`
  }
  if (winnerCardName) {
    url += `back=${winnerCardName}`
  }
  return (
    <>
      <div className="d-flex justify-content-center align-items-center" hidden={!loading} style={{position: 'absolute', top: 0, left: 0, bottom: 0, right: 0}}>
        <img style={{width: '50%'}} hidden={!loading} src={cardLoadingAnimation} />
      </div>
      <div>
        <iframe onLoad={onLoad} height={height} width="100%" className="iframeCard" scrolling="no" src={url} title="description"></iframe>
      </div>
      {
        !hideMessage && (
          <p className="text-center mintButtonsSmallParaCard mt-2 iframeSubheading">Interactive NFT: Click, Flip, Zoom, Move</p>
        )
      }
    </>
  )
}
export default TraitCard;
