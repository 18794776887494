import React, { useEffect, useState } from "react";
import { Fragment } from "react";
import { SessionContext } from "../store/SessionProvider";

const WorldView = (props) => {
  const [highestScoreName, setHighestScoreName] = useState('');
  const [mostCommonName, setMostCommonName] = useState('');
  const [{ result }] = SessionContext();

  // // useEffect
  useEffect(() => {
    if (!result) return
    const { mostCommonDistribution } = result;
    let highscore = 0, highscoreNameValue = "", mostCommon = 0, mostCommonNameValue = "";
    if(mostCommonDistribution) {
      mostCommonDistribution.forEach(element => {
        const total_Score_Distribution = parseFloat(element.total_Score_Distribution);
        const most_Common_Distribution = parseFloat(element.most_Common_Distribution);
        if (total_Score_Distribution > highscore) {
          highscore = total_Score_Distribution;
          highscoreNameValue = element.value_name;
        }
        if (most_Common_Distribution > mostCommon) {
          mostCommon = most_Common_Distribution;
          mostCommonNameValue = element.value_name;
        }
      });
      props.saveHighestNamesForPdf(highscoreNameValue,mostCommonNameValue)
      setHighestScoreName(highscoreNameValue);
      setMostCommonName(mostCommonNameValue);
    }
  }, [result]);

  if (!result) {
    return null;
  }
  const { winner, mostCommonDistribution } = result;

  return (
    <Fragment>
      {/* <!-- worlds view --> */}
      <div className="container">
        <div className="row px-2 px-md-5" style={{ justifyContent: 'center'}}>
          <div className="col-12">
            <h2 className="resultWorldsView my-5">Worlds View</h2>
          </div>
          {/* <!-- Value Systems --> */}
          
          <div className="text-md-right resultCardMainImageWrapper">
            {/* <img style={{width: 240,height: 350}} className="worldViewImg" src={winner!=undefined ? "https://pdfapi.personalitynft.com/public/images/"+winner.card_image : null} alt="mainCard1" /> */}
            {/* <a href="https://personalitynft.com/" target="_blank"> */}
            {
              !!winner && (
                <img
                  className="resultCardMain1 flip"
                  style={{ width: 321, height: 469, minHeight: 469 }}
                  src={"https://pdfapi.personalitynft.com/public/images/" + winner.card_image}
                  // src={resultCardMain1}
                  alt="card1"
                />
              )
            }
            {/* </a> */}
          </div>
          <div className="col-md-7 text-center">
            <h4 style={{marginLeft: '0px'}} className="resultWorldsViewSubHead my-4" id="winnerName">{winner ? winner.value_name : null}</h4>
            <p style={{ marginLeft: '0px' }} className="resultWorldsViewPara" id="winnerPrimary">Primary: {winner ? winner.value_name : null}</p>
            <p style={{ marginLeft: '0px' }} className="resultWorldsViewPara" id="winnerHighestScore">Highest Score: {winner ? highestScoreName : null}</p>
            <p style={{ marginLeft: '0px' }} className="resultWorldsViewPara" id="winnerMostCommon">Most Common: {winner ? mostCommonName : null}</p>
          </div>
          <div className="col-12 text-left mt-5 px-4">
            <h3 className="resultSystemValue" id="resultSystemValueTitle">World View & Value Systems</h3>
            <p className="resultSystemValuePara">
              Whenever you take an action, like reading this sentence, you do so at the exclusion of paying attention to other information in your environment. We pay attention to the things that we value and as a result we notice what’s important to us and exclude what isn’t. As we move forward in the world, everything we do is a byproduct of what we value and in this way our values shape the world we see and experience.
            </p>
            <p className="resultSystemValuePara">
              Values and the environment go hand in hand. Some environments, like work, may require a different set of values than when we are with friends or family. We operate from different value systems depending on our environment and so each of these value systems are potentials within us. So remember, that these Value Systems and World Views don’t represent types of people but types of values within people.
            </p>
            <p className="resultSystemValuePara">
              The Values Test identifies what you value most of the time under most circumstances. These are your overarching set of values that you take with you from one environment and experience to the next. Your Values can help you identify your primary goals in life and your ideals.
            </p>
          </div>
          {/* Other Result Values*/}
          <div className="col-12 text-left my-5">
            <h3 className="resultSystemValue" id="yourDetailedResults">Your Detailed Results</h3>
            <div className="row">
              <div className="col-4 resultTableHeading">Value Systems</div>
              <div className="col-4 resultTableHeading">Weighted Score</div>
              <div className="col-4 resultTableHeading">Most Common</div>
            </div>
            {winner && mostCommonDistribution ? mostCommonDistribution.map((item, index) => {
              return (
                // item.value_name!=winner.value_name && 
                  <div className="row">
                    <div className="col-4 resultTableData">{item.value_name}</div>
                    <div className="col-4 resultTableData">{item.total_Score_Distribution} %</div>
                    <div className="col-4 resultTableData">{item.most_Common_Distribution} %</div>
                  </div>
              )
            })
              : null
            }

          </div>
          {/* <!-- Value Systems --> */}
          <div className="col-12 text-left my-5">
            {/* <h3 className="resultSystemValue">World View & Value Systems</h3> */}
            <p className="resultSystemValuePara">
              {winner ?
                <span dangerouslySetInnerHTML={{ __html: winner.description }} />
                : null}
            </p>
          </div>
          {/* <!-- Value Systems END --> */}

        </div>
      </div>
      {/* <!-- worlds view end --> */}
    </Fragment>
  );
};

export default WorldView;
