export const supportedChains = [
  {
    name: "Goerli",
    short_name: "goerli",
    chain: "GoerliETH",
    network: "goerli",
    chain_id: 5,
    network_id: 5,
    rpc_url: "https://goerli.infura.io/v3/%API_KEY%",
    native_currency: {
      symbol: "GoerliETH",
      name: "GoerliETH",
      decimals: "18",
      contractAddress: "",
      balance: "",
    },
    explorer: "https://goerli.etherscan.io",
  },
];

export const networks = [
  {
    chainId: `0x${Number(5).toString(16)}`,
    chainName: "Goerli",
    nativeCurrency: {
      name: "GoerliETH",
      symbol: "GoerliETH",
      decimals: 18,
    },
    rpcUrls: ["https://goerli.infura.io/v3/"],
  },
];

