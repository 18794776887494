
import React, { Fragment } from "react";
import { WalletContext } from "../store/WalletProvider";
import "./Profile.css";
import { getPartialAddress } from "../../utils/string";
import { SessionContext } from "../store/SessionProvider";

const Profile = ({changeCurrentPage}) => {
  const [{ address }] = WalletContext();
  const [ { email, result }] = SessionContext();
  const { bigFiveTraitsDetails } = result;
  return (
    <Fragment>
      <div className="profileContainer">
        <div className="profileAvatarContainer">
          <div className="profileAvatar">
            <img className="imgAvatar" src="https://images.unsplash.com/photo-1615739778940-61d34b1e1ff6?ixlib=rb-4.0.3&ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&auto=format&fit=crop&w=735&q=80" alt="profileAvatar"/>
          </div>
          <div className="mt-3" style={{maxWidth: '400px'}}>
            <p style={{ fontWeight: 'bold' }} className="userName">Software Developer</p>
            {
              bigFiveTraitsDetails && bigFiveTraitsDetails.length > 0 ? (
                <p style={{ fontWeight: 'bold', fontSize: '1.15rem' }}>{bigFiveTraitsDetails[0]?.traitName} & {bigFiveTraitsDetails[1]?.traitName}</p>
              ) : (
                <p style={{ fontWeight: 'bold', fontSize: '1.15rem' }}>Please take quiz</p>
              )
            }
          </div>
        </div>

        <div className="profileDetails">
          <div>
            <p className="profileTitle">Profile Details</p>
            <div className="profileData">
              <p>Email: <span>{email}</span></p>
              <p>Twitter: <span>#twitterHandle12 (pending)</span></p>
              <p>Discord: <span>#discordHandle99 (pending)</span></p>
              <p>Birthday: <span>04/10/1998 (pending)</span></p>
              <p>Sex: <span>M/F (pending)</span></p>
            </div>
            <p style={{ fontSize: '1.35rem', fontWeight: 'bold' }}>Wallet Address: 
              {
                address ? (
                  <span style={{ letterSpacing: '2px', fontWeight: 'normal' }}> {getPartialAddress(address)}</span>
                ) : (
                  <span>
                    <button onClick={(e) => document.dispatchEvent(new Event('signin'))} className={`btn btnMata`}>
                      Connect Wallet
                    </button>
                  </span>
                )
              }
            </p>
            {
              address ? (
                <div className="profileBtnGroup">
                  <button className="profileConnectedBtn pt-2 pb-2" onClick={() => changeCurrentPage('MintCards')} >
                    See Earnings
                  </button>
                  <button className="profileConnectedBtn pt-2 pb-2" onClick={() => changeCurrentPage('MintCards')}>
                    Claim Payout
                  </button>
                </div>
              ) : null
            }
            <div className="matchSettings">
              <p className="matchTitle">Match Settings</p>
              <p>Allow matches to see your email address.</p>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default Profile;
