import React, { Fragment, useEffect, useState } from "react";

import DiscordPNG from "../../assets/Discord.PNG";
import PlaygroundPNG from "../../assets/Playground.PNG";

import TraitCard from "../UI/TraitCard";

import CommonGif from "../../assets/Rarity/Common.gif";
import EpicGif from "../../assets/Rarity/Epic.gif";
import LegendaryGif from "../../assets/Rarity/Legendary.gif";
import MythicalGif from "../../assets/Rarity/mythical.gif";
import RareGif from "../../assets/Rarity/Rare.gif";
import SuperRareGif from "../../assets/Rarity/SuperRare.gif";
import UncommonGif from "../../assets/Rarity/Uncommon.gif";

// import { useCoinGecko } from "@sunnyag/react-coingecko";
import axios from "axios";

import { WalletContext } from "../store/WalletProvider";

const MintCards = (props) => {
  const [{ address, mintPasses, nfts }] = WalletContext();

  const [winnerCardName, setWinnerCardName] = useState("");

  const [claimPayoutProcessing, setClaimPayoutProcessing] = useState(false)
  const [rewardsPayout, setRewardsPayout] = useState([]);

  // useEffect
  useEffect(async () => {
    if (props.winner != undefined) {
      if (props.winner.value_name == "Shaman") {
        setWinnerCardName("shaman")
      }
      else if (props.winner.value_name == "King" || props.winner.value_name == "Kings & Queens") {
        setWinnerCardName("king")
      }
      else if (props.winner.value_name == "The Order" || props.winner.value_name == "Holy Warrior") {
        setWinnerCardName("order")
      }
      else if (props.winner.value_name == "Achiever" || props.winner.value_name == "The Chariot") {
        setWinnerCardName("achiever")
      }
      else if (props.winner.value_name == "Egalitarian" || props.winner.value_name == "The Hermit") {
        setWinnerCardName("egalitarian")
      }
      else if (props.winner.value_name == "Integrator" || props.winner.value_name == "The Hanged Man | Integrator") {
        setWinnerCardName("Integrator")
      }
      else if (props.winner.value_name == "Oracle" || props.winner.value_name == "The Tower") {
        setWinnerCardName("oracle")
      }
      else if (props.winner.value_name == "Mystic") {
        setWinnerCardName("mystic")
      }
      else if (props.winner.value_name == "Survivalist") {
        setWinnerCardName("survivalist")
      }
    }

    callPaymentAndRewards();

    setTimeout(function () {
      const card = document.querySelector(".card__inner");
      card.classList.toggle('is-flipped');
    }, 1500);
  }, []);


  function callPaymentAndRewards() {
    if (!address) {
      return
    }
    axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin%2Cethereum&order=market_cap_desc&per_page=100&page=1&sparkline=false`).then(response => {
      let currentPrice = response.data[1].current_price

      axios.get(`${global.apiAddressBlockchain}/nfts/rewards?owner=${address}`).then(response => {
        if (response && response?.data) {
          const rewards = response?.data;
          const nftRewards = [];

          for (const reward in rewards) {
            let lifetime = 0;
            let unclaimed = 0;
            rewards[reward].forEach(element => {
              lifetime += element.amount;
              if (element.paid == false) {
                unclaimed += element.amount;
              }
            })

            let lifetime_in_dollars = currentPrice * lifetime;
            let unclaimed_in_dollars = currentPrice * unclaimed;
            let fixedNum = 2;

            nftRewards.push({
              nftId: reward,
              lifetime: lifetime_in_dollars.toFixed(fixedNum),
              unclaimed: unclaimed_in_dollars.toFixed(fixedNum)
            })
          }
          setRewardsPayout(nftRewards);
          setClaimPayoutProcessing(false)
        }
      })
    })
  }

  function claimPayout() {
    if (!address) {
      return
    }
    axios.post(`${global.apiAddressBlockchain}/nfts/pay-rewards?owner=${address}`).then(response => {
      console.log("response from pay-reward endpoint: ", response);
      callPaymentAndRewards();
    })
  }

  function getNFTRewardData(lifetime, unclaimed, nftId) {
    let lifetimeEarning = 0;
    let unclaimedPayout = 0;
    if (!rewardsPayout || rewardsPayout.length == 0) {
      return
    }
    for (let x = 0; x < rewardsPayout?.length; x++) {
      if (rewardsPayout[x].nftId == nftId) {
        lifetimeEarning = rewardsPayout[x].lifetime;
        unclaimedPayout = rewardsPayout[x].unclaimed;
      }
    }
    if (lifetime) {
      return (
        <h4 className="myNFTHeadingEarningAmount  mt-1 float-left">${lifetimeEarning}</h4>
      )
    }
    if (unclaimed) {
      return (
        <h4 className="myNFTHeadingEarningAmount mt-1 float-left">${unclaimedPayout}</h4>
      )
    }
  }


  return (
    <Fragment>
      {/* <!-- worlds view --> */}
      <div className="container">
        <div id="myNFT" className="text-center">
          {
            !address ? (
              <div className="matchesContainer">
                <h4 className="matchesTitle">Want to see your NFTs?</h4>
                <div className="matchesTable">
                  <button className="mintButtonRedCommunity1" onClick={() => document.dispatchEvent(new Event('signin'))}>
                    Please Connect Wallet to continue
                  </button>
                </div>
              </div>
            ) : null
          }
          {address && nfts ? nfts.map((element, index) => {
            return <div><div id="myNFTCommunityLinks" className="row mt-5">
              <div className="col-md-5 text-left align-items-start align-content-start col-sm-12">
                {/* iframe here */}
                {!!element.resultMain ?
                  <TraitCard 
                    conscientiousness={element.resultMain.conscientiousness}
                    agreeableness={element.resultMain.agreeableness}
                    openness={element.resultMain.openness}
                    extraversion={element.resultMain.extraversion} 
                    neuroticism={element.resultMain.neuroticism}
                    narcissism={element.resultMain.narcissism}
                    psychopathy={element.resultMain.psychopathy}
                    machiavellianism={element.resultMain.machiavellianism}
                    winnerCardName={winnerCardName}
                    height="469px"
                  />
                  : null}
              </div>
              <div className="col-md-7 col-sm-12 ">
                <h2 className="myNFTMainHeading">Personality NFTs</h2>
                <div className="">
                  <div className="row">
                    <div className="col-md-5">
                      <h4 className="myNFTSecondHeading text-left mt-4 mr-1">Rarity: {element.rarity} </h4>
                    </div>
                    <div className="col-md-7">
                      <h4 className="myNFTSecondHeading text-left mt-4">Mint #: {element.token_id}</h4>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="row">
                    <div className="col-md-5">
                      <h4 className="myNFTSecondHeading text-left mt-1 mr-5">Yield: {props.getYield(element.rarity)} </h4>
                    </div>
                    <div className="col-md-7">
                      <h4 className="myNFTSecondHeading text-left mt-1">Payout Levels: {props.getPayoutLevels(element.rarity)}</h4>
                    </div>
                  </div>
                </div>
                <div className="">
                  <div className="row">
                    <div className="col-md-5 myNFTThirdColumn">
                      <h4 className="myNFTHeadingEarnings text-left mt-1">Lifetime Earnings:</h4>
                    </div>
                    <div className="col-md-7">
                      <div className="lifeTimeEarningsMain pl-1 pr-1">
                        {getNFTRewardData(true, false, element.token_id)}
                        <button className="myNFTSeeDetailsEarningButton pl-3 pr-3 float-right mt-1" onClick={() => {
                          window.open("/earnings/" + element.token_id, "_blank")
                        }} >See Details </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mt-1">
                  <div className="row">
                    <div className="col-md-5 myNFTThirdColumn">
                      <h4 className="myNFTHeadingEarnings text-left mt-1">Unclaimed Payout</h4>
                    </div>
                    <div className="col-md-7 mt-1">
                      <div className="lifeTimeEarningsMain pl-1 pr-1">
                        {getNFTRewardData(false, true, element.token_id)}
                        <button onClick={(e) => {
                          setClaimPayoutProcessing(true)
                          claimPayout()
                        }} className="myNFTSeeDetailsEarningButton pl-2 pr-2 float-right mt-1" > {claimPayoutProcessing == false ? "Claim Payout" : "Processing..."} </button>
                      </div>
                    </div>
                  </div>
                </div>
                <h4 className="myNFTSecondHeading mt-2 text-left">Discount Link: Give $10 and Get Yield </h4>
                <div className="lifeTimeEarningsWhiteBackground mt-2 pl-1 pr-1 pb-1">
                  <h4 className="myNFTHeadingAddressLabel mt-1 float-left pt-1">{global.baseUrl}/discount-code/#{element.token_id}</h4>
                  <button className="myNFTSeeDetailsEarningButton pl-1 pr-1 float-right mt-1" onClick={() => {
                    navigator.clipboard.writeText(global.baseUrl + '/discount-code/#' + element.token_id)
                  }} >Copy </button>
                </div>
                <h4 className="myNFTSmallMessage mt-2 text-left">Share your Discount Link, Give $10 to your friends and earn a Yield on each mint. <a href="#">Learn More</a> </h4>
                <h4 className="myNFTSecondHeading mt-4 text-left">Community & Academy </h4>
                <div className="">
                  <div className="row">
                    <div className="col-md-6">
                      <button className="myNFTMediaButtons float-left mt-3" onClick={() => {
                      }} >
                        <img
                          className="mr-2 mt-1 mb-1"
                          style={{ width: 25, height: 25, minHeight: 25 }}
                          src={DiscordPNG}
                          alt="card1"
                        />
                        Discord
                      </button>
                    </div>
                    <div className="col-md-6">
                      <button className="myNFTMediaButtons myNFTMediaButtonsRed float-right mt-3" onClick={() => {
                      }} >
                        <img
                          className="mr-2 mt-1 mb-1"
                          style={{ width: 30, height: 30, minHeight: 30 }}
                          src={PlaygroundPNG}
                          alt="card1"
                        />
                        Playground</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
              <hr className="sperator" />
            </div>
          }) : null
          }
          <hr hidden className="sperator" />
          {address && mintPasses && mintPasses.map((element, index) => {
            return <div>
              <div id="commonMintPass" className="row mt-3 mt-md-5 commonMintPass">
                <div className="col-md-5 col-sm-12">
                  <img
                    className="iframeCard"
                    style={{ width: 290, height: 360, minHeight: 360 }}
                    src={element.rarity == "Common" ? CommonGif : element.rarity == "Epic" ? EpicGif : element.rarity == "Legendary" ? LegendaryGif : element.rarity == "Rare" ? RareGif : element.rarity == "Super Rare" ? SuperRareGif : element.rarity == "Uncommon" ? UncommonGif : element.rarity == "Mythical" ? MythicalGif : ""}
                    alt="Rarity"
                  />
                </div>
                <div className="col-md-7 col-sm-12 ">
                  <h2 className="myNFTMainHeading">Rarity Card</h2>
                  <h4 className="myNFTSecondHeading mt-4 ml-4 text-left">Upgrade Details: </h4>
                  <div className=" ml-4">
                    <div className="row">
                      <div className="col-md-6">
                        <h4 className="myNFTSecondHeading text-left mt-2 mr-1">Rarity: {element.rarity} </h4>
                      </div>
                      <div className="col-md-6">
                        <h4 className="myNFTSecondHeading text-left mt-2">Quantity: {element.balance}</h4>
                      </div>
                    </div>
                  </div>
                  <div className=" ml-4">
                    <div className="row">
                      <div className="col-md-6">
                        <h4 className="myNFTSecondHeading text-left mt-1 mr-5">Yield: {props.getYield(element.rarity)} </h4>
                      </div>
                      <div className="col-md-6">
                        <h4 className="myNFTSecondHeading text-left mt-1">Payout Levels: {props.getPayoutLevels(element.rarity)}</h4>
                      </div>
                    </div>
                  </div>
                  <div className=" ml-4 mt-2">
                    <button className="myNFTDowngradeRarityButton pt-1 pb-1" onClick={() => {
                      props.changeDowngradeModal();
                      localStorage.setItem('rarityForSwap', element.rarity);
                      localStorage.setItem('tokenIdForSwap', element.token_id);
                    }} >Switch Personality NFT rarity to {element.rarity} </button>
                  </div>
                  <h4 className="myNFTSmallMessage mt-4 ml-4 text-left">After you switch, you will be able to combine your
                    Current Personality NFT and upgrade your Rarity and Yield. Then
                    this Mint Pass will be burned! If you ever sell Personality NFT,
                    your personality data will be reset and the NFT will turn
                    back into this Mint Pass.</h4>
                </div>
              </div>
              {index != (mintPasses.length - 1) ? < hr className="sperator" /> : null}
            </div>
          })
          }
        </div>
      </div>
      {/* <!-- worlds view end --> */}
    </Fragment >
  );
};

export default MintCards;
