import Header from "../header/Header"

import WalletConnectProvider from "@walletconnect/web3-provider";
import Web3Modal from "web3modal";
import { useCallback, useEffect, useState } from "react";
import axios from "axios";
import { providers } from "ethers";
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { supportedChains } from "../../utils/chains";
import { WalletContext } from "../store/WalletProvider";
import classes from "../test1/Test.module.css";
import { getPartialAddress } from "../../utils/string";
import { SessionContext } from "../store/SessionProvider";
const providerOptions = {
  // binancechainwallet: {
  //   package: true
  // },
  walletconnect: {
    package: WalletConnectProvider, // required
    options: {
      infuraId: "95aa96c9790f4ec589ae6a517a3e3a83", // required
      bridge: "https://bridge.walletconnect.org",
    }
  }
};

//http://localhost:3000/result/45669.040885138515 

let web3Modal;
if (typeof window !== "undefined") {
  web3Modal = new Web3Modal({
    cacheProvider: true,
    providerOptions,
  });
  window.web3Modal = web3Modal;
}

export const Layout = ({ children, showMenu }) => {
  const [scriptData, setScriptData] = useState("");
  const [{ provider, web3Provider, address, nfts, mintPasses }, dispatchWallet] = WalletContext();

  const [{ currentPage, email, visitorId }, dispatchSession ] = SessionContext();

  const changeCurrentPage = (value) => dispatchSession({
    type: 'update',
    key: 'currentPage',
    value: value,
  })

  const connect = useCallback(
    async function (forceDisconnect) {
      const callback = window.callback || null;
      delete window.callback;
      try {
        if (forceDisconnect) {
          await web3Modal.clearCachedProvider();
        }
        const provider = await web3Modal.connect();
        const web3Provider = new providers.Web3Provider(provider);
        const signer = web3Provider.getSigner();
        const address = await signer.getAddress();
        const network = await web3Provider.getNetwork();
        const chain = supportedChains.find(
          (c) => c.chain_id == network.chainId
        );
        dispatchWallet({
          type: "multiUpdate",
          state: {
            loading: false,
            provider: provider,
            web3Provider: web3Provider,
            address: address,
            chainId: network.chainId,
            signer: signer,
            network: network.name,
            chain: chain,
          },
        });
        await axios.post(`${global.apiAdress}/api/result-page/add-address`, {
          visitorId,
          walletAddress: address,
        });
        if (callback) {
          callback();
        } else if (forceDisconnect) {
          alert("Success!! You wallet is now connected");
        }
      } catch (err) {
        console.log("error --> ", err);
      }
    },
    [dispatchWallet, web3Modal]
  );

  const disconnect = useCallback(
    async function () {
      if (!web3Modal) {
        return
      }
      await web3Modal.clearCachedProvider();
      if (provider?.disconnect && typeof provider.disconnect === "function") {
        await provider.disconnect();
      }
      dispatchWallet({
        type: "reset",
      });
    },
    [provider, dispatchWallet, web3Modal]
  );

  useEffect(() => {
    if (web3Modal && web3Modal.cachedProvider) {
      connect();
    } else {
      dispatchWallet({
        type: "multiUpdate",
        state: {
          loading: false,
        }
      });
    }
  }, [connect, web3Modal]);

  useEffect(() => {
    axios.get(`${global.apiAdress}/api/code-snippets/list`).then(response => {
      if (response.data.status = 200) {
        let CodeSnippetsRaw = response.data.code_snippets;
        const CodeSnippets = CodeSnippetsRaw.filter(
          (e) => e.code_page == "Result Page"
        );
        if (CodeSnippets.length > 0) {
          setScriptData(CodeSnippets[0].code);
        }
      }
    })

  }, [])

  useEffect(() => {
    const c = () => connect(true);
    document.addEventListener('signin', c);
    return () => document.removeEventListener('signin', c);
  }, [connect]);

  return (
    <div>
      <div id="ResultPage" className="container-fluid  h-100">
        { !!scriptData ? <div dangerouslySetInnerHTML={{ __html: scriptData }} /> : null }
        <div className="row h-100">
          <div className="col-11 px-0 mx-auto h-100 ">
            <div className={` ${classes.mainBoxWrapperResult}`}>
              <Header 
                connect={connect}
                disconnect={disconnect}
                web3Modal={web3Modal} 
                isResult={showMenu} />
              <div className={`container ${classes.mainBoxResult}`}>
                <div className={` ${classes.mainBoxAfterResult}`}>
                  {children}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="footer" >
        {/* <div className="row footerFlex"> */}
        <div className="footerFlex">
          <div className="col-3 pr-0 pl-0 ">
            <button className={currentPage == "Result" ? "resultFooterButton resultFooterButtonActive footerBorderRightWhite" : "resultFooterButton footerBorderRightWhite"} onClick={() => { changeCurrentPage("Result") }} >Result</button>
          </div>
          <div className="col-3 pr-0 pl-0">
            <button className={currentPage == "Community" ? "resultFooterButton resultFooterButtonActive " : "resultFooterButton "} onClick={() => { changeCurrentPage("Community") }} >Community</button>
          </div>
          <div className="col-3 pr-0 pl-0">
            <button style={{ color: "#ffffff", pointerEvents: "all"}}  className={currentPage == "MintCards" ? "resultFooterButton resultFooterButtonActive footerBorderLeftWhite" : "resultFooterButton footerBorderLeftWhite"} onClick={() => { changeCurrentPage("MintCards") }} >Nfts</button>
          </div>
          <div className="col-3 pr-0 pl-0">
            <DropdownButton
              variant="secondary"
              title={
                !email ? "Login" :
                  !web3Provider ? "Connect" : "Account"
              }
              className={`customFooterNav`}
            >
              <Dropdown.Item>
                {!email ?
                  <div onClick={
                    (e) => {
                      changeCurrentPage("Login")
                    }
                  }>Login
                  </div>
                  :
                  <label onClick={(e) => {
                    changeCurrentPage("Login")
                  }}>Logout
                  </label>
                }
              </Dropdown.Item>
              <Dropdown.Item>
                {
                  web3Provider ? (
                    <label onClick={(e) => {
                      disconnect()
                    }}>
                      {getPartialAddress(address)}
                      <br></br>
                      Disconnect Wallet
                    </label>
                  ) : (
                    <label onClick={
                      (e) => {
                        connect(true)
                      }
                    }>Connect
                    </label>
                  )
                }
              </Dropdown.Item>
            </DropdownButton>

            {/* <button className={currentPage == "Login" ? "resultFooterButton resultFooterButtonActive footerBorderLeftWhite" : "resultFooterButton footerBorderLeftWhite"} onClick={() => { changeCurrentPage("Login") }} >Login</button> */}
          </div>
        </div>
      </div>

      </div>
    </div>
  )
}
