import Main from "./components/mainPage/Main";
import Test from "./components/test1/Test";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import bg from "./assets/Spiral_Final.jpg";
import BackgroundMesh from "./assets/BackgroundMesh.png";
import classes from "./App.module.css";
import { useEffect } from "react";
import Aos from "aos";
import "aos/dist/aos.css";
import ReportResult from "./components/ResultPage/ReportResult";
import Test2 from "./components/test2/Test2";
import Test4 from "./components/test4/Test4";
import Result from "./components/ResultPage/Result";
import CommunityPage from "./components/CommunityPage/CommunityPage";
import MintPage from "./components/MintPage/MintPage";
import PersonalityCardDownloader from "./components/ResultPage/PersonalityCardDownloader";
import Earnings from "./components/Earnings";
import ForgotPassword from "./components/ResultPage/ForgotPassword/ForgotPassword";
import Profile from "./components/ResultPage/Profile";

global.baseUrl = "https://testing.personalitynft.com";
// global.apiAdress = "https://ptalaravelapi.unialsolutions.com";
global.apiAdress = "https://pdfapi.personalitynft.com";
// global.apiAdress = "http://localhost:8000";
global.apiAddressBlockchain = "https://blockchain.personalitynft.com";
global.discordApiAddress = "https://personality-apis-3dop4w4orq-uc.a.run.app";

global.personalityContracyAddress = "0xc06405F7d019114A695e24FB482f6D9A67f97EAC";
global.mintpassContracyAddress = "0x29d71c875e40EE29641555B9659FE575b631058A";
global.walletPrivateKey = "9117135a923766df52ca4fc481310b15daf21b8716430b264083a9b38d72ab59";
global.rpcURL = "https://goerli.infura.io/v3/688fb650c39045878f73a49845939381";

function App() {
  // for animation
  useEffect(() => {
    Aos.init({ duration: 700, delay: 100 });
    console.log(window.location.pathname)
  }, []);
  // for animation end

  return (
    <Router>
      <div className={window.location.pathname.includes("result") ? localStorage.getItem('downloadPDF')=="1" ? classes.mainBgFullHeightPDF: classes.mainBgFullHeight : classes.mainBg} style={{ overflow:"auto", backgroundImage: window.location.pathname.includes("earnings") ? `url(${BackgroundMesh})` : `url(${bg})` }}>
        <Routes>
          <Route path="/" element={<Main />} />
          <Route path="/test/:id" element={<Test />} />
          <Route path="/test2/:id" element={<Test2 />} />
          <Route path="/test4" element={<Test4 />} />
          <Route path="/result" element={<Result />} />
          <Route path="/result/:id" element={<Result />} />
          <Route path="/result-user/:id" element={<Result />} />
          <Route path="/reportResult/:id" element={<ReportResult />} />
          <Route path="/personality_card" element={ <PersonalityCardDownloader />} />
          {/* <Route path="/login" element={<Login />} /> */}
          <Route path="/community" element={<CommunityPage />} />
          <Route path="/profile" element={<Profile />} />
          <Route path="*" element={<Main />} />
          <Route path="/mint" element={<MintPage />} />
          <Route path="/earnings/:id" element={<Earnings />} />
          <Route path="/login" element={<Result />} />
          <Route path="/discount-code" element={<Main />} />
          <Route path="/forgot-password/:id" element={<ForgotPassword />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
