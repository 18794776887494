import axios from "axios";
import React, { createContext, useReducer, useContext, useCallback, useEffect } from "react";
import { alchemy } from "../../utils/alchemy";

const PageContext = createContext(); // create a store
const WalletProvider = ({ reducer, initialstate, children }) => {
  const [state, dispatch] = useReducer(reducer, initialstate);
  const { address } = state;

  const loadData = useCallback(async () => {
    if (!address) {
      dispatch({
        type: 'multiUpdate',
        state: {
          nfts: [],
          mintPasses: [],  
        },
      });
      return;
    }
    let mintedTokensAndResultByAddressForm = {
      walletAddress: address
    }
    let currentResultTokenId = '';
    let rarity = '';
    const [responseCoinPrice, responseReward, response] = await Promise.all([
      axios.get(`https://api.coingecko.com/api/v3/coins/markets?vs_currency=usd&ids=bitcoin%2Cethereum&order=market_cap_desc&per_page=100&page=1&sparkline=false`),
      axios.get(`${global.apiAddressBlockchain}/nfts/rewards?owner=${address}`),
      axios.post(`${global.apiAdress}/api/result-page/minted-tokens-and-result-by-address`, mintedTokensAndResultByAddressForm),
    ]);
    let currentPrice = responseCoinPrice.data[1].current_price;
    const [mintPassTokens, nftTokens] = await Promise.all([
      alchemy.nft.getNftsForOwner(address, {
        contractAddresses: [global.mintpassContracyAddress],
      }),
      alchemy.nft.getNftsForOwner(address, {
        contractAddresses: [global.personalityContracyAddress],
      }),
    ]);
    console.log("mintPassTokens, nftTokens", mintPassTokens, nftTokens)
    let extraversionData = "", opennessData = "", neuroticismData = "", conscientiousnessData = "", agreeablenessData = "";
    let machiavellianismData = "", narcissismData = "", psychopathyData = "", winnerData = "", commonDistribution = "";
    let winner = {}
    const [mintPasses, nfts] = await Promise.all([
      Promise.all(mintPassTokens.ownedNfts.reverse().map(async element => {
        debugger
        const responseMetaData = await axios.get(`${global.apiAddressBlockchain}/nfts/mintpass/metadata/${element.tokenId}`);
        return {
          token_id: element.tokenId,
          balance: element.balance,
          description: element.description,
          title: element.title,
          rarity: responseMetaData.data.rarity
        };
      })),      
      (await Promise.all(nftTokens.ownedNfts.reverse().map(async (element, index) => {
        const currentTokenData = responseReward.data[element.tokenId]
        let lifeTimeEarnings = 0;
        let unpaidEarnings = 0;
        if (currentTokenData != undefined && currentTokenData != null) {
          let totalEth = 0;
          let totalEthUnpaid = 0;
          currentTokenData.forEach(element => {
            totalEth = totalEth + (element.amount)
            if (element.paid == false) {
              totalEthUnpaid = totalEthUnpaid + (element.amount)
            }
          });
          console.log("totalEth", totalEth)
          let reward_in_dollars = currentPrice * totalEth;
          let fixedNum = 2;
          if (reward_in_dollars < 10) {
            fixedNum = 6;
          }
          else {
            fixedNum = 2;
          }
          lifeTimeEarnings = reward_in_dollars.toFixed(fixedNum);
          let reward_in_dollars_unpaid = currentPrice * totalEthUnpaid;
          if (reward_in_dollars_unpaid < 10) {
            fixedNum = 6;
          }
          else {
            fixedNum = 2;
          }
          unpaidEarnings = reward_in_dollars_unpaid.toFixed(fixedNum);

        }
        let lifeTimeEarningsValue = lifeTimeEarnings;
        let unpaidEarningsValue = unpaidEarnings;
        // response.data.minted_token.forEach(async (element) => {
        const mintedTokenValue = response.data.minted_token.filter(
          (e) => e.minted_card_token_id == element.tokenId
        );
        if (!mintedTokenValue.length) {
          return null
        }

        const responseMetaData = await axios.get(`${global.apiAddressBlockchain}/nfts/metadata/${element.tokenId}`);
        if (index == 0) {
          if (responseMetaData.data && responseMetaData.data.rarity) {
            rarity = responseMetaData.data.rarity;
          }
          currentResultTokenId = element.tokenId;
        }
        const userResultValue = response.data.users_result.filter(
          (e) => e.result_user_data_id == mintedTokenValue[0].minted_card_token_id
        );
        let resultMain = {};
        if (userResultValue.length > 0) {
          let traitsTable = response.data.test_traits;
          let subtraitsTable = response.data.sub_traits;

          let traitsPercentagesTable = response.data.result_traits;
          let subtraitsPercentagesTable = response.data.result_subtraits;

          let worldViewTable = response.data.result_values;

          let userResultTable = response.data.users_result;
          console.log(userResultTable)
          // let userResultTableLatestResult = userResultTable[userResultTable.length - 1];
          let userResultTableLatestResult = userResultValue[0];
          console.log(userResultTableLatestResult)
          let resultTraitsPercentagesRaw = JSON.parse(userResultTableLatestResult.result_user_data_percentages);
          let resultSubTraitsPercentagesRaw = JSON.parse(userResultTableLatestResult.result_user_data_subtrait_percentages);
          let resultWorldViewRaw = JSON.parse(userResultTableLatestResult.result_user_data_world_view_winner);
          let resultMostCommonDistributionRaw = JSON.parse(userResultTableLatestResult.result_user_data_world_view_common_distrubution);

          // setWinner(resultWorldViewRaw)
          const winnerRaw = worldViewTable.filter(
            (e) => e.id == resultWorldViewRaw.id
          );
          winnerData = {
            card_image: resultWorldViewRaw.card_image,
            id: resultWorldViewRaw.id,
            value_id: resultWorldViewRaw.value_id,
            value_name: resultWorldViewRaw.value_name,
            description: winnerRaw.description,
            value_result_link: winnerRaw.value_result_link
          };
          winner = winnerData;
          console.log(resultMostCommonDistributionRaw)
          commonDistribution = resultMostCommonDistributionRaw;

          let resultTraitsPercentages = [];
          let heighestPercentage = 0;
          let heighestTrait = "";
          let bigFiveTraits = [];
          let darkTraidTraits = [];

          resultTraitsPercentagesRaw.forEach(element => {
            const traitNameRaw = traitsTable.filter(
              (e) => e.id == element.traitId
            );
            let traitName = traitNameRaw[0].trait_title;
            const currentTraitResults = traitsPercentagesTable.filter(
              (e) => e.trait_id == element.traitId
            );
            let percentage = element.percentage;


            currentTraitResults.forEach(item => {
              let scoreSplit = item.score.split("-");
              let startScore = parseInt(scoreSplit[0])
              let endScore = parseInt(scoreSplit[1])
              if (heighestPercentage < percentage) {
                if (traitName != "Machiavellianism" && traitName != "Psychopathy" && traitName != "Narcissism") {
                  heighestPercentage = percentage;
                  heighestTrait = traitName;
                }
              }
              if (percentage >= startScore && percentage <= endScore) {
                if (traitName == "Extraversion") {
                  extraversionData = {
                    test_id: element.test_id,
                    traitId: element.traitId,
                    trait_id: element.traitId,
                    result_trait_id: item.id,
                    percentage: element.percentage.toFixed(2),
                    bullets: item.bullets,
                    description: item.description,
                    traitName: traitName
                  }
                  bigFiveTraits.push(extraversionData);
                }
                else if (traitName == "Agreeableness") {
                  agreeablenessData = {
                    test_id: element.test_id,
                    traitId: element.traitId,
                    trait_id: element.traitId,
                    result_trait_id: item.id,
                    percentage: element.percentage.toFixed(2),
                    bullets: item.bullets,
                    description: item.description,
                    traitName: traitName
                  }
                  bigFiveTraits.push(agreeablenessData);
                }
                else if (traitName == "Conscientiousness") {
                  conscientiousnessData = {
                    test_id: element.test_id,
                    traitId: element.traitId,
                    trait_id: element.traitId,
                    result_trait_id: item.id,
                    percentage: element.percentage.toFixed(2),
                    bullets: item.bullets,
                    description: item.description,
                    traitName: traitName
                  }
                  bigFiveTraits.push(conscientiousnessData);
                }
                else if (traitName == "Neuroticism") {
                  neuroticismData = {
                    test_id: element.test_id,
                    traitId: element.traitId,
                    trait_id: element.traitId,
                    result_trait_id: item.id,
                    percentage: element.percentage.toFixed(2),
                    bullets: item.bullets,
                    description: item.description,
                    traitName: traitName
                  }
                  bigFiveTraits.push(neuroticismData);
                }
                else if (traitName == "Openness") {
                  opennessData = {
                    test_id: element.test_id,
                    traitId: element.traitId,
                    trait_id: element.traitId,
                    result_trait_id: item.id,
                    percentage: element.percentage.toFixed(2),
                    bullets: item.bullets,
                    description: item.description,
                    traitName: traitName
                  }
                  bigFiveTraits.push(opennessData);
                }

                if (traitName == "Machiavellianism") {
                  machiavellianismData = {
                    test_id: element.test_id,
                    traitId: element.traitId,
                    trait_id: element.traitId,
                    result_trait_id: item.id,
                    percentage: element.percentage.toFixed(2),
                    bullets: item.bullets,
                    description: item.description,
                    traitName: traitName
                  }
                  darkTraidTraits.push(machiavellianismData);
                }
                else if (traitName == "Narcissism") {
                  narcissismData = {
                    test_id: element.test_id,
                    traitId: element.traitId,
                    trait_id: element.traitId,
                    result_trait_id: item.id,
                    percentage: element.percentage.toFixed(2),
                    bullets: item.bullets,
                    description: item.description,
                    traitName: traitName
                  }
                  darkTraidTraits.push(narcissismData);
                }
                else if (traitName == "Psychopathy") {
                  psychopathyData = {
                    test_id: element.test_id,
                    traitId: element.traitId,
                    trait_id: element.traitId,
                    result_trait_id: item.id,
                    percentage: element.percentage.toFixed(2),
                    bullets: item.bullets,
                    description: item.description,
                    traitName: traitName
                  }
                  darkTraidTraits.push(psychopathyData);
                }

                resultTraitsPercentages.push(
                  {
                    test_id: element.test_id,
                    traitId: element.traitId,
                    trait_id: element.traitId,
                    result_trait_id: item.id,
                    percentage: element.percentage.toFixed(2),
                    bullets: item.bullets,
                    description: item.description,
                    traitName: traitName
                  }
                )
              }

            });

          });
          // setHighestTrait(heighestTrait);
          console.log(resultTraitsPercentages);

          let resultSubTraitsPercentages = [];
          resultSubTraitsPercentagesRaw.forEach(element => {
            const subtraitNameRaw = subtraitsTable.filter(
              (e) => e.id == element.subtraitId
            );
            let subtraitName = subtraitNameRaw[0].subtrait_title;
            const currentTraitResults = subtraitsPercentagesTable.filter(
              (e) => e.subtrait_id == element.subtraitId
            );
            let percent = element.percent;
            currentTraitResults.forEach(item => {
              let scoreSplit = item.score.split("-");
              let startScore = parseInt(scoreSplit[0])
              let endScore = parseInt(scoreSplit[1])
              if (percent >= startScore && percent <= endScore) {
                resultSubTraitsPercentages.push(
                  {
                    subtraitId: element.subtraitId,
                    traitId: element.traitId,
                    percent: element.percent.toFixed(2),
                    result: {
                      description: item.description,
                      bullets: item.bullets
                    },
                    name: subtraitName
                  }
                )
              }

            });

          });
          console.log(resultSubTraitsPercentages);
          resultMain = {
            resultTraitsPercentages: resultTraitsPercentages,
            resultSubTraitsPercentages: resultSubTraitsPercentages,
            extraversion: extraversionData,
            openness: opennessData,
            neuroticism: neuroticismData,
            conscientiousness: conscientiousnessData,
            agreeableness: agreeablenessData,
            machiavellianism: machiavellianismData,
            narcissism: narcissismData,
            psychopathy: psychopathyData,
            winner: winnerData,
            mostCommonDistribution: commonDistribution,
            highestTrait: heighestTrait,
            bigFiveTraits: bigFiveTraits,
            darkTraidTraits: darkTraidTraits
          }
        }
        if (!mintedTokenValue.length) {
          return null
        }
        return {
          token_id: element.tokenId,
          inserted_user_id: mintedTokenValue[0].minted_card_token_id,
          minted_card_big_five_traits_percentages: mintedTokenValue[0].minted_card_big_five_traits_percentages,
          minted_card_dark_triad_traits_percentages: mintedTokenValue[0].minted_card_dark_triad_traits_percentages,
          minted_card_discount_code: mintedTokenValue[0].minted_card_discount_code,
          minted_card_discount_code_used: mintedTokenValue[0].minted_card_discount_code_used,
          minted_card_discount_link: mintedTokenValue[0].minted_card_discount_link,
          minted_card_nft_information: mintedTokenValue[0].minted_card_nft_information,
          minted_card_value_card: mintedTokenValue[0].minted_card_value_card,
          rarity: responseMetaData.data.rarity,
          resultMain: resultMain,
          lifeTimeEarnings: lifeTimeEarningsValue,
          unpaidEarnings: unpaidEarningsValue
        };
      }))).filter(v => !!v),
    ]);
    console.log("mintPasses, nfts", mintPasses, nfts)
    dispatch({
      type: 'multiUpdate',
      state: {
        nfts,
        mintPasses,  
        currentResultTokenId,
        rarity,
      },
    });
  }, [address]);

  useEffect(loadData, [loadData]);
  useEffect(() => {
    document.addEventListener('loadData', loadData);
    return () => document.removeEventListener('loadData', loadData);
  }, [loadData]);
  
  return (
    <PageContext.Provider value={[state, dispatch]}>
      {children}
    </PageContext.Provider>
  );
};

export const WalletContext = () => {
  return useContext(PageContext);
};

export default WalletProvider;
